import { errorTextRetry } from '@/constants';
import { buildURLFromParams, createError, submissionError } from '@/utils';

import transformer from './transformer';
import type { RequiredFormData } from './types';
import { parseError } from './utils';

const register = async (
  formData: RequiredFormData,
  { token, opportunityId }: { token: string; opportunityId: string },
) => {
  const url = buildURLFromParams({
    baseUrl: new URL(`${process.env.NEXT_PUBLIC_API_URL}/register`),
    params: {
      oppId: opportunityId,
      token,
    },
  });

  const abortController = new AbortController();

  const timeoutId = setTimeout(() => abortController.abort(), 60000);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      signal: abortController.signal,
      body: JSON.stringify(transformer(formData)),
    });

    if (response.status >= 500) {
      const responseError = await response.json();
      throw createError({
        error: new Error(responseError.error),
        field: responseError.field,
        message: responseError.error,
        name: responseError.field
          ? submissionError.INVALID_OPPORTUNITY
          : submissionError.ERROR,
      });
    }
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }
  } catch (error) {
    if (
      error instanceof DOMException ||
      error instanceof TypeError ||
      error instanceof SyntaxError
    ) {
      const timeout = createError({
        error: new Error(error.message),
        name: submissionError.TIMEOUT,
      });
      timeout.stack = error.stack;
      throw new Error(errorTextRetry('communicating with the server'));
    } else if (error instanceof Error) {
      if (error.name === submissionError.INVALID_OPPORTUNITY) {
        throw error;
      }
      throw new Error(parseError(error.message));
    }
  } finally {
    clearTimeout(timeoutId);
  }
};

export default register;
