import { NextRouter } from 'next/router';

import { Coercible } from '@/utils/validation';

type StringRecord = Record<string, string>;
type URLRecord = Record<string, Coercible>;

export const removeUrlTrailingSlash = (url: string): string => {
  let path = url;
  path = path.replace('/?', '?');
  return path.includes('?') ? path : path.replace(/\/$/, '');
};

/**
 * Takes an Object, removes nullable values
 * and returns an object with string values replaced.
 */
export const convertObjectToStringValues = (
  object: URLRecord,
): StringRecord => {
  return Object.entries(object).reduce<StringRecord>((acc, [key, value]) => {
    if (!value) return acc;

    acc[key] =
      typeof value === 'string' ? value.toString() : Number(value).toString();
    return acc;
  }, {});
};

export const buildURLFromParams = ({
  baseUrl,
  params,
}: {
  baseUrl: URL;
  params?: URLRecord;
}): string => {
  if (!params) {
    return removeUrlTrailingSlash(baseUrl.toString());
  }
  const urlParams = Object.fromEntries(
    new URLSearchParams(convertObjectToStringValues(params)),
  );
  Object.entries(urlParams).forEach(([key, value]) => {
    baseUrl.searchParams.append(key, value);
  });

  return removeUrlTrailingSlash(baseUrl.toString());
};

export const parseUrl = (
  path: string = '',
): { domain: string; queryParams?: string } => {
  const [parts] = [...path.matchAll(/([^?]+)(\?.*)?/g)];

  if (!parts) {
    return {
      domain: '',
    };
  }

  return {
    domain: removeUrlTrailingSlash(parts[1]),
    ...(parts![2] ? { queryParams: parts[2] } : {}),
  };
};

export const getUrlParam = (url: string, param: string) => {
  if (!url) return '';
  return new URL(url).searchParams.get(param);
};

export const getQueryParams = <T extends string>(
  router: Pick<NextRouter, 'asPath' | 'query'>,
  ...params: T[]
) => {
  // router.query will not have its params set on first load
  // so rather do hook gymnastics, infer the params from `asPath`
  const { queryParams } = parseUrl(router.asPath);
  const fallback = new URLSearchParams(queryParams);
  const keys = params.length
    ? params
    : (Object.keys(Object.fromEntries(fallback)) as T[]);

  return keys.reduce((acc, next) => {
    acc[next] = String(router.query[next] || fallback?.get(next) || '');
    return acc;
  }, {} as Record<T, string>);
};

export const buildPathFromParams = (
  router: Pick<NextRouter, 'asPath' | 'pathname' | 'query'>,
  additionalParams: URLRecord = {},
): string => {
  const paramString = new URLSearchParams({
    ...getQueryParams(router),
    ...convertObjectToStringValues(additionalParams),
  }).toString();

  return paramString.length
    ? `${router.pathname}?${paramString}`
    : router.pathname;
};
