import type { ReactNode } from 'react';
import { di } from 'react-magnetic-di';

import { Notification } from '@/components/Notification';

import {
  Aside,
  AsideColumn,
  CenteredColumn,
  Children,
  Container,
} from './TwoColumn.styled';

export type Props = {
  aside?: ReactNode;
  children: ReactNode;
};

const TwoColumn = ({ children, aside }: Props) => {
  di(Aside, AsideColumn, CenteredColumn, Children, Container, Notification);

  return (
    <Container>
      <Children>
        <CenteredColumn>
          <Notification />
          {children}
        </CenteredColumn>
      </Children>
      <Aside>{aside && <AsideColumn>{aside}</AsideColumn>}</Aside>
    </Container>
  );
};

export default TwoColumn;
