import createUseService from '@/utils/create-use-service';

import service from './service';

export type { RequiredFormData } from './types';

export const {
  useServiceError: useServiceRegisterError,
  useServiceActions: useServiceRegisterActions,
} = createUseService(service, 'register');
