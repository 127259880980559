import { ReactNode } from 'react';

import Head from 'next/head';
import Image from 'next/image';
import MegaphoneSvg from 'public/megaphone-logo.svg';

import { Favicons } from '@/components/FavIcons';
import { Header, Main } from '@/components/Layout/Layout.styled';

type Props = {
  children?: ReactNode;
  hasLogo?: Boolean;
  title?: string;
};

const Layout = ({ children, hasLogo, title = 'Megaphone' }: Props) => (
  <>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <Favicons />
    </Head>
    {hasLogo && (
      <Header>
        <Image src={MegaphoneSvg} height="24" width="116" />
      </Header>
    )}
    <Main>{children}</Main>
  </>
);

export default Layout;
