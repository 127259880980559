import React, { ComponentType, useEffect, useRef } from 'react';
import { useFormState } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { useServiceRegisterError } from '@/services/register';
import { useServiceStripeError } from '@/services/stripe';
import { submissionError } from '@/utils';

import { NotificationStyled } from './Notification.styled';

export const Notification: ComponentType = () => {
  di(
    NotificationStyled,
    useFormState,
    useServiceRegisterError,
    useServiceStripeError,
  );

  const ref = useRef<HTMLDivElement>(null);
  const { isSubmitting } = useFormState();
  const errorRegister = useServiceRegisterError()[0] as Error | void;
  const errorStripe = useServiceStripeError()[0] as Error | void;

  const error =
    !isSubmitting &&
    (errorStripe?.message ||
      (errorRegister?.name !== submissionError.INVALID_OPPORTUNITY
        ? errorRegister?.message
        : ''));

  useEffect(() => {
    if (error) {
      ref.current?.scrollIntoView?.({ block: 'center' });
    }
  }, [error]);

  return error ? (
    <NotificationStyled ref={ref}>{String(error)}</NotificationStyled>
  ) : null;
};

export default Notification;
