import { Col, Container, Row } from 'react-grid-system';
import { di } from 'react-magnetic-di';

import { spacer16, spacer24, Type } from '@spotify-internal/encore-web';
import Image from 'next/image';
import TangleWireSvg from 'public/tangled-wire.svg';

import { Card } from '@/components/Card';
import { Layout } from '@/components/Layout';
import { supportUrl } from '@/constants';

const NotFound = () => {
  di(Card, Col, Container, Image, Layout, Row, Type);

  return (
    <Layout hasLogo>
      <Container fluid>
        <Row align="center" justify="center" style={{ height: '100vh' }}>
          <Col xs={12}>
            <Card style={{ textAlign: 'center' }}>
              <div style={{ marginBlockEnd: spacer24 }}>
                <Image
                  aria-hidden
                  src={TangleWireSvg}
                  width="120"
                  height="120"
                />
              </div>
              <Type
                as="h1"
                variant="canon"
                semanticColor="textBase"
                paddingBottom={spacer16}
              >
                Something went wrong
              </Type>
              <Type as="p" variant="viola" paddingBottom={spacer24}>
                Sorry, this page cannot be found.
              </Type>
              <Type
                as="a"
                href={supportUrl}
                semanticColor="textBrightAccent"
                target="_blank"
              >
                Contact Support
              </Type>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFound;
