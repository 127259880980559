import { countries } from 'countries-list';

import { countryPattern } from '@/utils';

export const countryOptions = Object.entries(countries)
  .filter(([c]) => c.match(countryPattern))
  .map(([key, value]) => ({
    value: key,
    label: value.name,
  }));

export const findCountryByKey = (key: keyof typeof countries) => countries[key];
