export type Coercible = string | number | boolean | undefined | null;

export const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}$/;

export const unicodePattern = /^([ \u00c0-\u01ffa-zA-Z\.,0-9\/'-])+$/;

export const unicodeNamePattern = /^([ \u00c0-\u01ffa-zA-Z\.0-9'-])+$/;

export const countryPattern = /^US|FR|ES|GB|IT|SE|DE$/;

export const interPattern = /^[1-9][0-9]*$/;

export const checkAllTruthy = (
  obj: Record<string, Coercible>,
  ...excludedKeys: string[]
): boolean => {
  return Object.entries(obj).every(([key, value]) => {
    return excludedKeys.includes(key) || !!value;
  });
};

export const trimValue = (...values: Coercible[]): string => {
  return values
    .map(value => (!!value ? value.toString().trim() : ''))
    .join(' ')
    .trim();
};
