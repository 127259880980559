type Plan = 1;

export const planProfessional: Plan = 1;

export interface RequestData {
  billing_address_1: string;
  billing_address_2?: string;
  billing_city: string;
  billing_contact: string;
  billing_country: string;
  billing_country_code: string;
  billing_email: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_state: string;
  billing_zip: string;
  email: string;
  first_name: string;
  last_name: string;
  organization_name: string;
  organization_type: Plan;
  payment_method: string;
}

export interface FetchContactResponseData {
  organizationName: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface FormData {
  billing_address_1: string;
  billing_address_2?: string;
  billing_city: string;
  billing_consent: boolean;
  billing_country_code: 'US';
  billing_email: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_state: string;
  billing_zip: string;
  country: 'US';
  email: string;
  first_name: string;
  last_name: string;
  organization_name: string;
  payment_method: string;
}

export interface PDFData {
  billing_address: string;
  billing_city: string;
  billing_contact: string;
  billing_contact_title?: string;
  billing_country: string;
  billing_email: string;
  billing_state?: string;
  billing_zip: string;
  oppId: string;
  organization_name: string;
  token: string;
}

export type BillingData = Pick<
  FormData,
  | 'billing_address_1'
  | 'billing_address_2'
  | 'billing_city'
  | 'billing_consent'
  | 'billing_country_code'
  | 'billing_email'
  | 'billing_first_name'
  | 'billing_last_name'
  | 'billing_state'
  | 'billing_zip'
>;

export type Key = keyof FormData;

const defaultBillingValues: BillingData = {
  billing_address_1: '',
  billing_address_2: '',
  billing_city: '',
  billing_consent: false,
  billing_country_code: 'US',
  billing_email: '',
  billing_first_name: '',
  billing_last_name: '',
  billing_state: '',
  billing_zip: '',
};

export const defaultValues: FormData = {
  country: 'US',
  email: '',
  first_name: '',
  last_name: '',
  organization_name: '',
  payment_method: '',
  ...defaultBillingValues,
};
