import { planProfessional } from '@/data';
import { findCountryByKey } from '@/data/countries';

import { RequestData, RequiredFormData } from './types';

const transformFormDataIntoRequestData = ({
  first_name,
  last_name,
  email,
  organization_name,
  billing_first_name,
  billing_last_name,
  billing_email,
  billing_address_1,
  billing_address_2,
  billing_city,
  billing_state,
  billing_zip,
  billing_country_code,
  payment_method,
}: RequiredFormData): RequestData => ({
  first_name,
  last_name,
  email,
  organization_name,
  organization_type: planProfessional,
  billing_first_name,
  billing_last_name,
  billing_contact: `${billing_first_name} ${billing_last_name}`,
  billing_email,
  billing_address_1,
  billing_address_2,
  billing_city,
  billing_state,
  billing_zip,
  billing_country: findCountryByKey(billing_country_code).name,
  billing_country_code,
  payment_method,
});

export default transformFormDataIntoRequestData;
