export const parseError = (message: string): string => {
  try {
    const error = JSON.parse(message);
    const firstKey = Object.keys(error)[0];
    if (typeof error[firstKey] === 'string' || Array.isArray(error[firstKey])) {
      return String(error[firstKey]);
    }
    throw message;
  } catch (error) {
    if (message && error instanceof SyntaxError) {
      return message;
    }
    return 'An unknown error has occurred.';
  }
};
