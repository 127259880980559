import { ReactNode, useRef } from 'react';

import { spacer24 } from '@spotify-internal/encore-web';

import { headerHeight } from '@/constants';

import { Background, CenteredColumn } from './CenteredSingleColumn.styled';

export type Props = {
  children: ReactNode;
};

const CenteredSingleColumn = ({ children }: Props) => {
  const centeredColumn = useRef<HTMLDivElement>(null);

  // Calculate the min height of the document, taking into account
  // that contents are centered vertically ( double the height of the header )
  const height = (parseInt(headerHeight, 10) + parseInt(spacer24, 10)) * 2;
  const minHeight = (centeredColumn.current?.clientHeight || 0) + height;

  return (
    <Background minHeight={minHeight}>
      <CenteredColumn ref={centeredColumn}>{children}</CenteredColumn>
    </Background>
  );
};

export default CenteredSingleColumn;
