import { spacer24, spacer32, spacer64 } from '@spotify-internal/encore-web';
import styled from 'styled-components';

import { headerHeight } from '@/constants';
import { breakpoints } from '@/gridConfiguration';

export const Header = styled.header`
  min-height: ${headerHeight};
  padding-left: ${spacer32};
  padding-top: ${spacer24};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-left: ${spacer64};
    position: absolute;
  }
`;

export const Main = styled.main`
  position: relative;

  @media screen and (min-width: ${breakpoints.md}) {
    height: 100vh;
  }
`;
