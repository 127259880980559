export type MetaData = [name: string, meta: Record<string, any>];

interface CreateError extends Record<string, unknown> {
  error: Error;
  name?: string;
  metaData?: MetaData;
  message?: string;
}

export enum submissionError {
  ERROR = 'error',
  INVALID_OPPORTUNITY = 'invalid_opportunity',
  STRIPE = 'stripe',
  TIMEOUT = 'timeout',
}

export interface CustomError extends Error, Record<string, unknown> {}

export const createError = ({
  error,
  message,
  name,
  ...rest
}: CreateError): CustomError => {
  error.name = name || 'Error';
  error.message = message || error.message;

  return Object.assign(error, rest);
};
