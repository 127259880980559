import type { Stripe, StripeElements } from '@stripe/stripe-js';

import { errorTextGeneric } from '@/constants';

const service = async (
  stripe: Stripe,
  elements: StripeElements,
): Promise<string> => {
  const cardElement = elements.getElement('cardNumber');
  const { token, error } = await stripe.createToken(cardElement!);
  if (error) {
    throw error;
  }
  if (!token?.id) {
    throw new Error(errorTextGeneric('creating your account'));
  }
  return token.id;
};

export default service;
