import {
  Action,
  createActionsHook,
  createHook,
  createStore,
} from 'react-sweet-state';

const storeNameSet = new Set<string>();

const createUseService = <T extends (...args: any[]) => any>(
  fetchFn: T,
  uniqueStoreName: string,
) => {
  type State = {
    data: Awaited<ReturnType<T>> | undefined;
    error: unknown;
    isLoading: boolean;
    promise: ReturnType<T> | undefined;
  };

  const storeName = `useService:${uniqueStoreName}`;

  if (storeNameSet.has(storeName)) {
    throw new Error(
      `Duplicate store name "${storeName}" found in the set of store names.`,
    );
  }
  storeNameSet.add(storeName);

  const actions = {
    run:
      (
        ...args: Parameters<T>
      ): Action<State, void, Promise<Awaited<ReturnType<T>>>> =>
      async ({ getState, setState }): Promise<Awaited<ReturnType<T>>> => {
        if (getState().isLoading)
          return getState().promise as Awaited<ReturnType<T>>;

        const promise = fetchFn(...args);
        setState({
          ...getState(),
          isLoading: true,
          promise,
        });

        try {
          const data = await promise;
          setState({
            ...getState(),
            data,
            error: undefined,
            isLoading: false,
          });
        } catch (error) {
          setState({
            ...getState(),
            data: undefined,
            error,
            isLoading: false,
          });
          throw error;
        }
        return promise;
      },
  };

  const store = createStore<State, typeof actions>({
    initialState: {
      data: undefined,
      error: undefined,
      isLoading: false,
      promise: undefined,
    },
    actions,
    name: storeName,
  });

  const useService = createHook(store);
  const useServiceData = createHook(store, {
    selector: (state: State) => state.data,
  });
  const useServiceError = createHook(store, {
    selector: (state: State) => state.error,
  });
  const useServiceIsLoading = createHook(store, {
    selector: (state: State) => state.isLoading,
  });
  const useServicePromise = createHook(store, {
    selector: (state: State) => state.promise,
  });

  const useServiceActions = createActionsHook(store);
  return {
    useService,
    useServiceData,
    useServiceError,
    useServiceIsLoading,
    useServicePromise,
    useServiceActions,
  };
};

export default createUseService;
