import { CSSProperties, ReactNode } from 'react';

import { CardStyled, CardStyledProps } from './Card.styled';

export type Props = CardStyledProps & {
  children?: ReactNode;
  hasShadow?: boolean;
  style?: CSSProperties;
};

const Card = ({ children, hasShadow, style }: Props) => (
  <CardStyled hasShadow={!!hasShadow} style={style}>
    {children}
  </CardStyled>
);

export default Card;
