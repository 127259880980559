import {
  blueViolet75,
  spacer20,
  spacer24,
  spacer48,
  spacer64,
  spacer80,
} from '@spotify-internal/encore-foundation';
import styled from 'styled-components';

import { breakpoints } from '@/gridConfiguration';

export const Container = styled.div`
  display: grid;
  height: 100%;

  @media screen and (min-width: ${breakpoints.lg}) {
    grid-template-columns: 2fr 1fr;
  }

  @media screen and (min-width: ${breakpoints.xxl}) {
    // this is eye-balled in, and will need to change if the breakpoint changes
    grid-template-columns: 1000px 1fr;
  }
`;

export const Children = styled.div``;

export const CenteredColumn = styled.div`
  margin: auto;
  max-width: ${breakpoints.md};
  padding: ${spacer20};
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${spacer64};
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    padding-left: ${spacer48};
    padding-right: ${spacer48};
  }

  @media screen and (min-width: ${breakpoints.xxl}) {
    // this is eye-balled in, and will need to change if the breakpoint changes
    margin-left: 225px;
  }
`;

export const Aside = styled.div`
  @media screen and (min-width: ${breakpoints.lg}) {
    background-color: ${blueViolet75};
  }
`;

export const AsideColumn = styled(CenteredColumn)`
  padding: 0 ${spacer20} ${spacer64};

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-left: 0;
    max-width: ${breakpoints.sm};
    padding-top: ${spacer64};
    padding-left: 0;
    padding-right: 0;
    transform: translateX(-${spacer24});
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    transform: translateX(-${spacer80});
  }
`;
