import { blueViolet75 } from '@spotify-internal/encore-foundation';
import styled from 'styled-components';

import { breakpoints } from '@/gridConfiguration';

export const Background = styled.div<{ minHeight: number }>`
  position: relative;

  @media screen and (min-width: ${breakpoints.md}) {
    height: 100vh;
    background-image: linear-gradient(0, ${blueViolet75} 50%, white 50%);
    min-height: ${({ minHeight }) => `${minHeight}px`};
  }
`;

export const CenteredColumn = styled.div`
  margin: auto;
  max-width: ${breakpoints.md};
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}) {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
