import {
  solidWhite200,
  spacer8,
  spacer20,
  spacer24,
  spacer40,
  spacer48,
} from '@spotify-internal/encore-web';
import styled, { css } from 'styled-components';

import { breakpoints } from '@/gridConfiguration';
import { boxShadow } from '@/styles';

export const applyShadow = () => css`
  @media screen and (min-width: ${breakpoints.md}) {
    box-shadow: ${boxShadow};
  }
`;

export type CardStyledProps = {
  hasShadow?: boolean;
};

export const CardStyled = styled.div<CardStyledProps>`
  background: ${solidWhite200};
  ${({ hasShadow }) => hasShadow && applyShadow()};
  padding: ${spacer24} ${spacer20};

  @media screen and (min-width: ${breakpoints.md}) {
    border-radius: ${spacer8};
    padding: ${spacer48} ${spacer40};
  }
`;
